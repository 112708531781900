<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
        <ul>
          <li class="pb-3">The Hidden Curriculum, Baldwin, A. (2020). <em>College success: 2.7 The hidden curriculum</em>. OpenStax. <a href="https://openstax.org/books/college-success/pages/2-7-the-hidden-curriculum" target="_blank">openstax.org/books/college-success/pages/2-7-the-hidden-curriculum </a></li>
          <li class="pb-3">Teaching the Hidden Curriculum Trustees of Boston University. (2021). <em>Teaching the hidden curriculum</em>. Boston University: Teaching Writing. <a href="https://www.bu.edu/teaching-writing/resources/teaching-the-hidden-curriculum/" target="_blank">www.bu.edu/teaching-writing/resources/teaching-the-hidden-curriculum/</a></li>
          <li class="pb-3">Arao, B., & Clemens, K. (2013). From safe spaces to brave spaces: A new way to frame dialogue around diversity and social justice. In L. M. Landreman (Ed.), <em>The art of effective facilitation</em> (pp. 135-150). Stylus Publishing, LLC.</li>
          <li class="pb-3">Makoelle, T. M., & Malindi, M. (2015). Resilience: A framework for inclusive pedagogy in a South African context. Electronic Journal for Inclusive Education, Vol. 3, No. 3 (Winter/Spring 2015).</li>
          <li class="pb-3">Mills, K. J. (2021). Black students’ perceptions of campus climates and the effect on academic resilience. <em>Journal of Black Psychology</em>, 47(4-5), 354-383. <a href="https://journals.sagepub.com/doi/full/10.1177/00957984211001195" target="_blank">https://journals.sagepub.com/doi/full/10.1177/00957984211001195</a></li>
          <li class="pb-3">Carleton College. (2021, April 5). <em>Resilient Pedagogy - Learning and Teaching Center - Carleton College</em>. <a href="https://www.carleton.edu/ltc/resilient-pedagogy/" target="_blank">www.carleton.edu/ltc/resilient-pedagogy/</a></li>
          <li class="pb-3">The University of Waterloo. (n.d.). <em>Resilient Course Design</em>. Centre for Teaching Excellence, University of Waterloo. <a href="https://uwaterloo.ca/centre-for-teaching-excellence/teaching-resources/teaching-tips/planning-courses-and-assignments/course-design/resilient-course-design" target="_blank">uwaterloo.ca/centre-for-teaching-excellence/teaching-resources/teaching-tips/planning-courses-and-assignments/course-design/resilient-course-design </a></li>
          <li class="pb-3">University of Calgary. Promoting Reflection and Resilience through Course Design, University of Waterloo. <a href="https://taylorinstitute.ucalgary.ca/news/promoting-reflection-and-resilience-through-course-design" target="_blank">taylorinstitute.ucalgary.ca/news/promoting-reflection-and-resilience-through-course-design</a></li>
          <li class="pb-3">University of Missouri - St. Louis. (n.d.) Re-envisioning Classroom Activities in the Online Environment [Google Doc]. <a href="https://docs.google.com/document/d/1bKI37aRCTKrBy25eyM04yNTtqW8uU1xorQAlY1zjm6Q/edit" target="_blank">docs.google.com/document/d/1bKI37aRCTKrBy25eyM04yNTtqW8uU1xorQAlY1zjm6Q/edit#</a></li>
          <li class="pb-3">Shafi, Adeela , Middleton, Tristan , Millican, Richard  and Templeton, Sian  (2017) Developing academic buoyancy and resilience through the assessment feedback process. In: British Education Studies Association Annual Conference, 28th to 30th June 2017, Liverpool Hope University. (Unpublished)</li>
          <li class="pb-3">Ahmed Shafi, A., Hatley, J., Middleton, T., Millican, R., & Templeton, S. (2017). The role of assessment feedback in developing academic buoyancy. Assessment & Evaluation in Higher Education, 43(3), 415–427.  <a href="https://www.tandfonline.com/doi/full/10.1080/02602938.2017.1356265" target="_blank">www.tandfonline.com/doi/full/10.1080/02602938.2017.1356265 </a></li>
          <li class="pb-3">Inside Higher Ed. (2019, January 14). <em>My professor cares: Can “light-touch, targeted feedback” to students improve their perceptions and performance. </em>. <a href="https://www.insidehighered.com/news/2019/01/14/can-light-touch-targeted-feedback-students-improve-their-perceptions-and-performance" target="_blank">www.insidehighered.com/news/2019/01/14/can-light-touch-targeted-feedback-students-improve-their-perceptions-and-performance  </a></li>
          <li class="pb-3">APRIL 2019 A Call to Action: Academic Skill Deficiencies in Four Ontario Universities CONTRIBUTORS J. Paul Grayson (York University), James Côté (Western University), Liang Chen (University of Toronto), Robert Kenedy (York University), and Sharon Roberts (University of Waterloo)</li>
          <li class="pb-3"><em>Thriving in Action Online</em>. (2021). Thriving in Action. <a href="https://tia.ryerson.ca" target="_blank">tia.ryerson.ca/</a></li>
          <li class="pb-3">McMasterUTV. (2020, July 23). <em>Building Academic Resilience: Practices for teaching students to embrace and learn from failure</em>. YouTube. <a href="https://www.youtube.com/watch?v=BmAasU9U66Q" target="_blank">https://www.youtube.com/watch?v=BmAasU9U66Q</a></li>
          <li>Brecher, D, Shaffer, D. “The Holistic Learning Strategies Toolbox: Bringing in the Breath and the Body”.</li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
